<template>
    <div class="bg-gradient-primary">
        <div class="container p-4">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-7 col-xl-6">
                    <div class="card shadow-lg o-hidden my-5">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h4 class="text-dark mb-4">
                                                Welcome Back!
                                            </h4>
                                        </div>
                                        <ValidationObserver
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form
                                                class="user"
                                                @submit.prevent="
                                                    handleSubmit(onSubmit)
                                                "
                                                novalidate
                                            >
                                                <div class="form-group">
                                                    <ValidationProvider
                                                        name="E-mail"
                                                        rules="required|email"
                                                        v-slot="{
                                                            valid,
                                                            errors,
                                                        }"
                                                    >
                                                        <b-form-input
                                                            class="form-control form-control-user"
                                                            type="email"
                                                            placeholder="Email Address"
                                                            name="email"
                                                            :state="
                                                                errors[0]
                                                                    ? false
                                                                    : valid
                                                                    ? true
                                                                    : null
                                                            "
                                                            v-model="user.email"
                                                        >
                                                        </b-form-input>
                                                        <span
                                                            class="small text-danger"
                                                        >
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="form-group">
                                                    <ValidationProvider
                                                        name="Password"
                                                        rules="required"
                                                        v-slot="{
                                                            valid,
                                                            errors,
                                                        }"
                                                    >
                                                        <b-form-input
                                                            class="form-control form-control-user"
                                                            type="password"
                                                            placeholder="Password"
                                                            name="password"
                                                            :state="
                                                                errors[0]
                                                                    ? false
                                                                    : valid
                                                                    ? true
                                                                    : null
                                                            "
                                                            v-model="
                                                                user.password
                                                            "
                                                        >
                                                        </b-form-input>
                                                        <span
                                                            class="small text-danger"
                                                        >
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </div>
                                                <button
                                                    class="btn btn-success btn-block text-white btn-user"
                                                    type="submit"
                                                    :disabled="loading"
                                                >
                                                    Login
                                                </button>
                                            </form>
                                        </ValidationObserver>
                                        <hr />
                                        <div class="text-center">
                                            <router-link
                                                to="/register"
                                                class="small"
                                            >
                                                Don't have an Account?
                                                Createone!
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            user: {
                email: '',
                password: '',
            },
            loading: false,
        }
    },
    mounted() {
        const q = this.$route.query

        // If the user comes from an LTI consumer, the tokens are passed in the query string:
        if (q && q.access_token) {
            this.$store.commit('login', {
                accessToken: q.access_token,
                refreshToken: q.refresh_token,
                userRoles: q.user_roles.split(','),
            })
            this.$router.push(q.redirect || '/game').catch((err) => {})
        }
    },
    methods: {
        onSubmit() {
            this.loading = true
            this.$store.dispatch('login', this.user).then(
                () => {
                    this.$router
                        .push(this.$route.query.redirect || '/game')
                        .catch((err) => {})
                },
                (error) => {
                    this.loading = false
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
    },
}
</script>
<style scoped>
.form-control {
    font-size: 16px;
}

.form-control,
.btn {
    border-radius: 50px;
    outline: none !important;
}
</style>
